<script setup lang="ts">
   import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline';

   const { surveyQuestion, currentStep, lastQuestionIndex } =
      storeToRefs(useSurveyStore());
   const { locale } = useI18n();

   const widthProgress = computed(() => {
      if (surveyQuestion.value) {
         return `${((lastQuestionIndex.value + 1) / surveyQuestion.value?.questions?.length) * 100}%`;
      } else return '0%';
   });

   const goBack = () => {
      if (currentStep.value < 1) return;
      currentStep.value = currentStep.value - 1;
   };
</script>

<template>
   <div
      class="w-auto cursor-pointer mb-5"
      v-if="lastQuestionIndex != 0"
      @click.prevent="goBack"
   >
      <ArrowLeftIcon v-if="locale == 'en'" class="h-4 w-4 text-secondary-400" />
      <ArrowRightIcon v-else class="h-4 w-4 text-secondary-400" />
   </div>
   <div class="flex items-center space-x-2">
      <span class="text-sm font-medium">
         {{ lastQuestionIndex + 1 }}/{{
            surveyQuestion?.questions?.length || 1
         }}
      </span>
      <div class="w-full h-2 bg-primary-100 rounded-full">
         <div
            class="h-2 bg-primary-500 rounded-full transition-all duration-500 ease-in-out"
            :style="{ width: widthProgress }"
         ></div>
      </div>
   </div>
</template>
