import { defineStore } from 'pinia';
import apiRoutes from '@/const/apiRoutes';
import type { Question, SurveyResponse } from '~/types/SurveyResponse';

export const useSurveyStore = defineStore('surveyStore', () => {
   const { setSnackbarDetail } = useSnackbarStore();
   const surveyQuestion: Ref<SurveyResponse | null> = ref(null);
   const currentStep = ref(-1);
   const lastQuestionIndex = computed(() => {
      return currentStep.value >= 0 ?
            currentStep.value
         :  surveyQuestion.value?.questions?.findIndex(
               (item: Question) => item.responses.length === 0
            ) || 0;
   });
   const getSurveyQuestion = async () => {
      const { data, error } = await useIFetch<SurveyResponse>(
         apiRoutes?.client?.survey.get
      );
      if (data.value) {
         surveyQuestion.value = data.value;
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data?.message,
            type: 'error',
         });
         return;
      }
      currentStep.value = lastQuestionIndex.value;
   };
   const submitSurvey = async (
      questionId: string,
      answer: { answer_ids?: string[]; answer_text?: string }
   ) => {
      const { data, error } = await useIFetch<SurveyResponse>(
         apiRoutes?.client?.survey.answer(questionId),
         {
            method: 'POST',
            body: answer,
         }
      );
      if (data?.value) {
         setSnackbarDetail({
            show: true,
            title: (data.value as any)?.message,
            type: 'message',
         });
      }
      if (error?.value) {
         setSnackbarDetail({
            show: true,
            title: error.value.data.message,
            type: 'error',
         });
         return error.value;
      }
   };

   const nextStepSurvey = async () => {
      await getSurveyQuestion();
      currentStep.value = currentStep.value + 1;
   };
   return {
      getSurveyQuestion,
      surveyQuestion,
      lastQuestionIndex,
      submitSurvey,
      currentStep,
      nextStepSurvey,
   };
});
