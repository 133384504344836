<template>
   <div>
      <BaseLayout />
   </div>
   <BaseDialog
      v-model:open-dialog="isPendingClientFeedback"
      size="sm"
      preserve-log
   >
      <template #header></template>
      <VideoCallClientFeedback
         @close="closePendingFeedback"
         @success="checkIsPendingClientFeedback"
         :data="clientInformation?.pending_booking"
         :is-pending="true"
      ></VideoCallClientFeedback>
   </BaseDialog>
   <BaseDialog
      v-model:open-dialog="isOpenSurvey"
      title=""
      preserve-log
      :is-mobile-full-width="true"
      :no-padding="true"
   >
      <ClientSurveyDialog @close="isOpenSurvey = false"></ClientSurveyDialog>
   </BaseDialog>
</template>

<script setup lang="ts">
   const isPendingClientFeedback = ref(false);
   const isOpenSurvey = ref(false);
   import { usePusherStore } from '@/stores/pusher.pinia';

   const { connectToPusher } = usePusherStore();
   const { clientInformation } = storeToRefs(useClientInfo());
   const checkIsPendingClientFeedback = () => {
      if (clientInformation?.value?.pending_booking) {
         isPendingClientFeedback.value = true;
      } else {
         checkIfIsSurvey();
      }
   };
   const checkIfIsSurvey = () => {
      if (clientInformation?.value?.client?.has_survey) {
         isOpenSurvey.value = true;
      }
   };
   const closePendingFeedback = () => {
      isPendingClientFeedback.value = false;
      checkIfIsSurvey();
   };
   onMounted(() => {
      connectToPusher(true);
      checkIsPendingClientFeedback();
   });
</script>
